



































































import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import TableComponent from '@common-src/mixins/table-component';
import { ViewModeType } from '@common-src/model/enum';
import { ServiceCenterEntityModel, ServiceCenterQueryModel } from '@common-src/entity-model/service-center-entity';
import ServiceCenterService from '@common-src/service/service-center';
import CommonService from '@common-src/service/common';
import WorkOrderDetailComponent from '@/pages/dashboard/work-order/wo-detail.vue';
@Component({
    components: {
        'work-order-detail-component': WorkOrderDetailComponent
    }
})
export default class ServiceCenter extends Mixins(TableComponent, DrawerComponent) {
    workOrderOptions = [];

    selectedTabKey = 'AssignedTo';

    selectedWoConfigId = '';

    selectedWorkOrderId = '';

    viewType = 0;

    ViewModeType = ViewModeType;

    modelVisible = false;

    evaluation = { serviceAttitude: 5, responseSpeed: 5, serviceQuality: 5, content: '' };

    tabList = [
        {
            value: 'AssignedTo',
            name: '待分派'
        },
        {
            value: 'TimeOut',
            name: '超时未认领'
        },
        {
            value: 'ToClose',
            name: '待关闭'
        },
        {
            value: 'ToEvaluation',
            name: '待评价'
        }];

    ServiceCenterEntityModel = ServiceCenterEntityModel;

    service:ServiceCenterService;

    created() {
        CommonService.getWorkOrderOptions().then(res => {
            this.workOrderOptions = res;

            this.pageSize = 10;

            this.service = new ServiceCenterService(false);

            this.tableColumns = ServiceCenterEntityModel.getTableColumns();

            this.queryModel = new ServiceCenterQueryModel();

            (this.queryModel as any).serviceDeskStatus = this.selectedTabKey;

            this.getServiceList();
        });
    }

    tabChanged(key) {
        this.selectedTabKey = key;

        (this.queryModel as any).serviceDeskStatus = this.selectedTabKey;

        this.getServiceList();
    }

    addRequestService(value) {
        this.viewType = ViewModeType.NEW;

        this.selectedWoConfigId = value.key;

        this.drawerVisible = true;
    }

    showDetail(viewtype, record) {
        this.viewType = viewtype;

        this.selectedWorkOrderId = record.id;

        if (record.assignee === '' && viewtype === ViewModeType.UPDATE) {
            this.startFullScreenLoading();

            // 如果受理人为空，先认领
            this.service.saveForm('to_Claim', {}, {}, record.id).then(res => {
                this.$eventHub.$emit('GET_WORKORDER_NUMBERS');

                this.getList();

                this.drawerVisible = true;

                this.stopFullScreenLoading();
            });
        } else {
            this.drawerVisible = true;
        }
    }

    showDrawer(info) {
        this.getServiceList();

        this.drawerVisible = info.visible;
    }

    showEvaluation(record) {
        this.selectedWorkOrderId = record.id;

        this.modelVisible = true;
    }

    handleOk() {
        let params = Object.assign({ orderId: this.selectedWorkOrderId }, this.evaluation);

        this.service.saveEvaluation(params).then(res => {
            this.modelVisible = false;

            this.showMessageSuccess('评价成功！');

            this.getServiceList();
        });
    }

    getServiceList() {
        this.getList().then(res => {
            if (this.selectedTabKey === 'ToClose') {
                this.listData = this.listData.filter((item:any) => { return item.flowStatus !== 'end'; });
            }
        });
    }
}
